const FirebaseConfig = {
  apiKey: "AIzaSyCzxm-bnNPhBVA5rGUYXADNx1j4_v5vPr8",
  authDomain: "website-arredobimbo.firebaseapp.com",
  projectId: "website-arredobimbo",
  storageBucket: "website-arredobimbo.appspot.com",
  messagingSenderId: "463706240967",
  appId: "1:463706240967:web:ffeac3c9b4f4c229468be2",
  measurementId: "G-F2F77BER6X"
};

export default FirebaseConfig

