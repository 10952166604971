import {initializeApp} from "firebase/app"
import {getFirestore} from 'firebase/firestore/lite';
import {
	createUserWithEmailAndPassword,
	FacebookAuthProvider,
	getAuth,
	GoogleAuthProvider,
	signInWithEmailAndPassword,
	signInWithPopup,
	signOut
} from 'firebase/auth'
import {getStorage} from "firebase/storage";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import firebaseConfig from 'configs/FirebaseConfig';

const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);
//const storageRef = initializeApp(firebaseConfig).storage().ref();

const currentUser = auth.currentUser
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();

export {
	db,
	auth,
	storage,
	currentUser,
	googleAuthProvider,
	GoogleAuthProvider,
	facebookAuthProvider,
	FacebookAuthProvider,
	signInWithEmailAndPassword,
	signOut,
	signInWithPopup,
	createUserWithEmailAndPassword
};