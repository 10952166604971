import React from 'react'
import {APP_PREFIX_PATH, AUTH_PREFIX_PATH} from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'home',
        path: `${APP_PREFIX_PATH}/home`,
        component: React.lazy(() => import('views/app-views/home')),
    },
    {
        key: 'apps-ecommerce-orders',
        path: `${APP_PREFIX_PATH}/ordini`,
        component: React.lazy(() => import('views/app-views/orders')),
    },
    {
        key: 'apps-ecommerce-productList',
        path: `${APP_PREFIX_PATH}/ecommerce/lista-prodotti`,
        component: React.lazy(() => import('views/app-views/e-commerce/product-list')),
    },
    {
        key: 'apps-ecommerce-addProduct',
        path: `${APP_PREFIX_PATH}/ecommerce/aggiungi-prodotto`,
        component: React.lazy(() => import('views/app-views/e-commerce/add-product')),
    },
    {
        key: 'apps-ecommerce-handle-photos',
        path: `${APP_PREFIX_PATH}/gestione-foto`,
        component: React.lazy(() => import('views/app-views/handle-images')),
    },
    {
        key: 'apps-ecommerce-editProduct',
        path: `${APP_PREFIX_PATH}/ecommerce/modifica-prodotto/:id`,
        component: React.lazy(() => import('views/app-views/e-commerce/edit-product')),
    }
]