const dev = {
	API_ENDPOINT_URL: 'https://server.maffle.it:8443/arredobimbo/sviluppo'
};
const coll = {
	API_ENDPOINT_URL: 'https://server.maffle.it:8443/arredobimbo/collaudo'
};
const prod = {
	API_ENDPOINT_URL: 'https://arredobimbo.com:8443'
};

const getEnv = () => {
	switch (process.env.REACT_APP_ENV) {
		case 'sviluppo':
			return dev
		case 'collaudo':
			return coll
		case 'produzione':
			return prod
		default:
			return {
				API_ENDPOINT_URL: 'http://localhost:8080'
			};
	}
}




export const env = getEnv()
